import React, {FC} from "react";
import {IProduct} from "../../../types/homePageTypes";
import {handleAddCardClickWithSellProducts} from "../../shared/ProductCard/helpers";
import {useDispatch, useSelector} from "react-redux";
import {useRouter} from "next/router";
import {IState} from "../../../types/state";
import {setCrossValid, setPopupName, setTempData} from "../../../store/general";
import {getUpOrCrossProd} from "../../../store/cart";
import {ICurrencies} from "../../ForCWV/Wrappers/MainLayout";
import shopApi from "../../../api/shop";

interface IProps {
    item: {
        buttonType: string;
        buttonValue: string;
        settings: any;
        targeting: "_blank" | "";
        linkValueButton: string;
        productForBuyNow: IProduct;
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: any;
            parentStyles: any
        }
    },
    selectedLocale: string,
    backOrderValue: string,
    currencies: Array<ICurrencies>;
}

const Button: FC<IProps> = (
    {
        item: {
            buttonType,
            buttonValue,
            linkValueButton,
            targeting,
            productForBuyNow,
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
                styles = {},
                parentStyles = {}
            } = {}
        },
        selectedLocale = "",
        backOrderValue = "",
        currencies: [{
            code: selectedCurrCode = "",
            symbol: selectedCurrSymbol = "",
            exchange_rate: {rate: selectedCurrRate = "1"} = {}
        } = {}] = []
    }
) => {
    const {
        qty: [productQuantity = 0] = [],
        id: productId = 0,
        flats: [{
            has_up_sell = 0,
            has_cross_sell = 0,
            url_key = "",
            name: productName = "",
            short_description: productShortDesc = "",
            price: productPrice = "",
            special_price: productSpecialPrice = ""
        } = {}] = [],
        images: [imagePath] = []
    } = productForBuyNow || {}

    const cartToken = useSelector((state: IState) => state.cartToken);
    const customer = useSelector((state: IState) => state.customer);

    let tagEntry = `<p>${buttonValue}</p>`;
    styles['border'] = "none";
    styles['font-size'] = "16px";
    styles['cursor'] = "pointer";
    const {color: linkColor, "font-size": fontSizeLink, fontFamily: fontFamilyLink} = styles || {}
    const linkStyles = {
        color: linkColor,
        fontSize: fontSizeLink,
        fontFamily: fontFamilyLink
    }
    const dispatch = useDispatch()
    const {push: routerPush} = useRouter();

    const isProductInStock = productQuantity > 0 || !!parseFloat(backOrderValue)

    const cartDataObj = {
        product: productForBuyNow,
        quantity: 1,
        cartToken,
        customer: customer as { token: string },
        selectedLocale,
        selectedCurrCode,
        optionsObject: null,
        addToast: () => {
        }
    }

    const upCrossSellHandle: Record<string, Record<string, () => void>> = {
        "true": {
            "true": () => {
                dispatch(setPopupName("upsell"));
                dispatch(getUpOrCrossProd(
                    productId,
                    'up-sell',
                    selectedLocale,
                    selectedCurrCode,
                    1,
                    {exchange_rate: {rate: selectedCurrRate}, symbol: selectedCurrSymbol, code: selectedCurrCode}
                ))
                dispatch(setTempData([productForBuyNow]))
            },
            "false": () => upCrossSellHandle["true"]["true"](),
        },
        "false": {
            "true": () => {
                dispatch(getUpOrCrossProd(
                    productId,
                    'cross-sell',
                    selectedLocale,
                    selectedCurrCode,
                    1,
                    {exchange_rate: {rate: selectedCurrRate}, symbol: selectedCurrSymbol, code: selectedCurrCode}
                ))
                dispatch(setCrossValid(true));
                dispatch(setPopupName("crossSell"));
                dispatch(setTempData([productForBuyNow]))
            },
            "false": () => {
            }
        }
    }

    const openUpCrossProd = () => {
        return upCrossSellHandle[`${!!parseFloat(has_up_sell)}`][`${!!parseFloat(has_cross_sell)}`]()
    }

    const handleBuyNow = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        shopApi.getEmptyCart().then(res => {
            if (res.message) {
                handleAddCardClickWithSellProducts(
                    openUpCrossProd, dispatch, cartDataObj, routerPush, url_key
                )[`${isProductInStock}`]
                    [`${!!parseFloat(has_up_sell)}`]
                    [`${!!parseFloat(has_cross_sell)}`]();

                routerPush("/checkout")
            }
        })
    }

    const handleButtonType = {
        "true": <button
            style={styles}
            dangerouslySetInnerHTML={{__html: tagEntry}}
            onClick={handleBuyNow}
        />,
        "false": <button style={styles}>
            <a
                style={linkStyles}
                href={linkValueButton}
                rel={"noreferrer"}
                dangerouslySetInnerHTML={{__html: tagEntry}}
                target={targeting}
            />
        </button>
    }

    return (
        <div data-aos={dataAos} style={parentStyles}>
            {handleButtonType[`${buttonType === "Buy now"}`]}
        </div>
    );
}

export default Button;